@import "normalize";
@import "roboto";
@import "autocomplete";
@import "in-bool";

* { font-family: 'roboto'; }
body {
  // background-color: lighten($base-color, 30%);
  background-color: #fff;
  padding-top: 20px;
}

a.button {
  display: inline-block;
  border-radius: 3px;
  background-color: #eee;
  color: #000;
  text-decoration: none;
  padding:3px 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  font-size: 12px;
  font-weight: 500;

  &.blue {
    background-color: #a0f1e6;
  }

  &.disabled {
    background-color: #eee;
    color: #999;
    box-shadow: none;
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
}

.page-accueil, .page-config {
  h1 {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    font-weight: 200;
    font-size: 35px;
    color: #333;
    text-transform: uppercase;
    
  }
  & > div {
    background-color: #fff;
    h2 {
      font-weight: 600;
      margin: 0 0 15px 0;
      // text-align: left;
      color: #555;
      text-transform: uppercase;
      img {
        height: 32px;
        width: auto;
        margin-right: 10px;
      }
    }

    div.resultat {
      text-align: center;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 10px 0;
      border-radius: 20px;
      background-color: #fafafa;
      &.ko {
        background-color: #F3FCED;
      }
      &.ok {
        background-color: #edf6fc;
      }
      
      img {
        margin-right: 20px;
      }
      &.no-result {
        font-style: italic;
        color: #999;
      }
      b {
        color: #555;
        font-weight: 600;
      }
    }

    .hint {
      font-style: italic;
      color:$base-color;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 20px;
      padding-left: 20px;
    }

    border-radius: 20px;
    margin: 30px auto;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 20px 10px;
    // text-align: center;
    img { width: 64px; }
  }
}

.page-config {
  table {
    margin: 0 auto;
    border-collapse: collapse;
    font-size: 12px;
    td,th {
      border: 1px solid #999;
      text-align: center;
      padding: 10px 5px;
      min-width: 37px;
    }
  }
  .autoComplete_wrapper {
    .in-ac {
      height: 25px;
      background-image: none;
      padding-left: 10px;
      font-size: 12px;
      font-weight: bold;
      margin-top:9px;
      
    }
    .in-ac:focus::placeholder {
      padding: 0.1rem 0.6rem;
      font-size: 12px;
      color: rgba( $base-color, .4 );
    }
  }
  .in-voie .in-ac {
    width: 250px;
  }
  .in-numero .in-ac {
    width: 100px;
  }
}